import { useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import { ImgUrl } from "./func"
import { useLocation } from 'react-router-dom'


const Header = (pp) => {
    const btn = useRef(null)

    const onClickMenu = () => {

    }
    //mobile menu
    return (
        <>
            {/* <nav class="d-md-none navbar navbar-expand-lg navbar-light bg-light">
                <div class="sp-menu container-fluid d-lg-none position-fixed top-0 left-0 bg-white p-2 zindex-1">
                    <a class="navbar-brand" href="/">Millmol</a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" ref={btn}>
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            {navItems.map(item => {
                                return (
                                    <li class="nav-item" onClick={() => btn.current.click()} key={item.key}>
                                        <a class="nav-link active" aria-current="page" href={item.href? item.href:"/#" + item.key}>
                                            {item.display}
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </nav> */}
        </>
    )
}

export const navItems = [
    { "key": "about", "display": "ABOUT" },
    { "key": "works", "display": "WORKS", "href": "/page/works" },
    { "key": "member", "display": "MEMBER" },
    { "key": "news", "display": "NEWS" },
    { "key": "contact", "display": "CONTACT" },
]

export const CommonHeader = () => {
    const btn = useRef(null)

    const onClickMenu = () => {

    }
    return (
        <>
            <Menu />
            <nav class="d-md-none navbar navbar-expand-lg navbar-light bg-light">
                <div class="sp-menu container-fluid d-lg-none position-fixed top-0 left-0 bg-white p-2 zindex-1">
                    <a class="navbar-brand" href="/">Millmol</a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" ref={btn}>
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            {navItems.map(item => {
                                return (
                                    <li class="nav-item" onClick={() => btn.current.click()} key={item.key}>
                                        <a class="nav-link active" aria-current="page" href={item.href ? item.href : "/#" + item.key}>
                                            {item.display}
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export const HomeIcon = () => {
    return (<>
        <p className="center mt-5"><Link to="/"><img src={ImgUrl("home.png")} width="32" className="home-icon" alt="home" /></Link></p>
    </>)
}

//common back menu
const Menu = (p) => {

    return (
        <nav className="nav-common d-none d-md-block">
            <ul className='main-menu list-group list-group-horizontal text-decoration-none'>
                <MenuItem title="Back" hash="member" />
            </ul>
        </nav>
    )
}
const MenuItem = (p) => {
    var href = "/"
    switch (useLocation().pathname) {
        case "/page/works":
            href = "/#about"
            break
    }
    if (useLocation().pathname.includes("member/"))
        href = "/#member"
    return (
        <Link to={href}>
            <li className='list-group-item' style={{ fontFamily: 'Cormorant Garamond' }}>{p.title}</li>
        </Link>
    )
}

export const ContactButton = () => {
    return (
        <p className="center">
            <Link to="/#contact"><button className="btn btn-success element mt-4">お問い合わせ</button></Link>
        </p>
    )
}

export default Header