import { useState, useEffect, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import SanitizedHTML from "react-sanitized-html"
import { Link, useLocation } from "react-router-dom"
import { ImgUrl } from "./func"
import SNS from "./SNS"
import { SNS2 } from "./SNS"
import { Grid } from "@mui/material"
import { maxWidth } from "@mui/system"
import * as my from "./myfunc"
import WOW from 'wowjs'
import 'animate.css/animate.min.css';

function Home(pp) {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        window.WOW = WOW;
        new WOW.WOW().init();
    }, [])

    return (
        <>
            <div className="star"></div>
            <div className="outer">
                {/* <Menu /> */}
                <div className='container-fluid main'>
                    <div className="row center">
                        <img src="./img/millmol_logo.png" className="wow fadeInDown mt-5" alt="millmol_logo" width="80%" style={{ maxWidth: "300px", margin: "auto" }} /><br />
                        <p style={{ fontSize: "4em", fontFamily: 'Spirax, Cormorant Garamond' }} className="wow fadeInDown" data-wow-delay="0.5s">
                            <img src="./img/millmol_w.png" alt="millmol" width="80%" style={{ maxWidth: "300px", margin: "auto" }} />
                        </p>
                    </div>
                </div>
                <h1 className="color text-center mt-6 mb-6" style={{ fontSize: '3rem' }}>Millmolは新たなホームページへと生まれ変わっています。</h1>
                {/* <About /> */}
                {/* <Member /> */}
                {/* <News /> */}
                {/* <Contact /> */}
                <SNS2 />
            </div>
            <StarrySky />
        </>
    );
}

const StarrySky = () => {
    const [stars, setStars] = useState([]);

    useEffect(() => {
        // 星の数
        const starCount = 100;
        const starsArray = [];

        // 星をランダムに生成
        for (let i = 0; i < starCount; i++) {
            const size = Math.random() * 3 + 1; // 星の大きさ
            const posX = Math.random() * window.innerWidth;
            const posY = Math.random() * window.innerHeight;
            const duration = Math.random() * 100 + 50; // アニメーションの持続時間

            starsArray.push({
                size,
                posX,
                posY,
                duration,
            });
        }

        setStars(starsArray);
    }, []);

    return (
        <div className="star-container">
            {stars.map((star, index) => (
                <div
                    key={index}
                    className="star"
                    style={{
                        width: `${star.size}px`,
                        height: `${star.size}px`,
                        top: `${star.posY}px`,
                        left: `${star.posX}px`,
                        animationDuration: `${star.duration}s`,
                    }}
                />
            ))}
        </div>
    );
};

//pc menu
const Menu = () => {
    return (
        <nav className="nav-top d-md-block d-none">
            <ul className='main-menu list-group list-group-horizontal text-decoration-none'>
                <MenuItem title="ABOUT" />
                <MenuItem title="WORKS" href="./page/works" />
                <MenuItem title="MEMBER" />
                <MenuItem title="NEWS" />
                <MenuItem title="CONTACT" />
            </ul>
        </nav>
    )
}

const About = (pp) => {
    return (
        <div className='news pt-6 container wow fadeIn' data-wow-delay="1s" id="about">

            <div className='row'>
                <div className='col-12 fs-5 -d-flex -align-items-center -justify-content-center' style={{}}>
                    <h1 className="color" style={{ fontSize: '4rem' }}>世界に境界を持たないクリエイター集団</h1>
                    <p className="fs-2">Millmolは、これから始まる新たな時代のコンテンツを生み出し、
                        世界に技術を繋いでいきたいという想いから立ち上がった、クリエイター集団です。</p>
                </div>
            </div>
            <p className="center">
                <Link to="/page/works"><button className="btn btn-success element mt-4">実績はこちら</button></Link>
            </p>
        </div>
    )
}

const MenuItem = (p) => {
    return (
        <a href={p.href != undefined ? p.href : "#" + p.title.toLowerCase()}>
            <li className='list-group-item' style={{ fontFamily: 'Cormorant Garamond' }}>{p.title}</li>
        </a>
    )
}

const Member = (p) => {
    return (
        <>
            <div className="pt-6 container" id="member">
                <h2 className='mb-5'>Member</h2>
                <Grid container justifyContent="center" rowSpacing={5} style={{ maxWidth: "8300px", margin: "auto" }}>
                    <MemberItem name="saika" position="代表" delay="0s" />
                    <MemberItem name="tatsu" position="副代表" delay="0.1s" />
                    <MemberItem name="iroha" position="エンジニア" delay="0.2s" />
                </Grid>
            </div>
        </>
    )
}

const MemberItem = (p) => {
    return (
        <Grid item md={3} xs={6}>
            <Link to={"/member/" + p.name}>
                <div className="center">
                    <img src={ImgUrl(p.name + ".jpg")} alt="" className="circle wow fadeInDown" data-wow-delay={p.delay} /><br />
                    {p.name.toUpperCase()}<br />
                    <span className="small">{p.position}</span><br />
                </div>
            </Link>
        </Grid>
    )
}

const News = () => {
    const [newsJson, setNewsJson] = useState("")
    const previewLength = 80
    useEffect(() => {
        fetch("https://millmol.com/wp/wp-json/wp/v2/posts?orderby=date&order=desc")
            .then((res) => {
                if (res.ok)
                    return res.json()
            })
            .then((res) => {
                setNewsJson(res.map((news) => {

                    let content = news.content.rendered.replace(/<[^>]*>/g, '').substring(0, previewLength)
                    return (
                        <Link to={"/news?p=" + news.id} id={news.id} key={news.id}>
                            <div className="list-group-item list-group-item-action flex-column align-items-start">
                                <div className="d-flex w-100 justify-content-between">
                                    <h5 className="mb-1">
                                        <span className='fs-6 date'>{news.date.substring(0, 10)}</span>
                                        <span> {news.title.rendered}</span>
                                    </h5>
                                </div>
                                <small></small>
                            </div>
                        </Link>
                    )
                }))
            })
            .catch((e) => {
                console.log(e)
            })
    }, [])


    return (
        <div className='news pt-6 container' id="news">
            <h1>News</h1>
            <div className="list-group wow fadeInLeft">
                {newsJson}
            </div>
        </div>
    )
}

const Contact = () => {

    const [isSent, setIsSent] = useState(false)
    const [isError, setIsError] = useState(false)
    const [isChk, setIsCheck] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const ref = useRef(false)

    const onChangeChk = (e) => {
        const f = document.forms[0];
        const re = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/

        if (e.target.checked) {
            if (f.name.value == "") {
                alert("お名前を入力してください。")
                e.target.checked = false
                return
            } else if (!re.test(f.email.value)) {
                alert("正しいメールアドレスを入力してください。")
                e.target.checked = false
                return
            } else if (f.message.value.length < 20) {
                alert("内容は20文字以上で記載してください。")
                e.target.checked = false
                return
            }
        }

        setIsCheck(e.target.checked)
        setIsDisabled(!e.target.checked)
    }

    const post = (e) => {
        const postData = new FormData;
        const f = document.forms[0];
        postData.set('name', f.name.value);
        postData.set('email', f.email.value);
        postData.set('message', f.message.value);

        const data = {
            method: 'POST',
            body: postData
        };

        fetch('./3ab5/index.php', data)
            .then((res) => {
                if (!res.ok) {
                    setIsError(true)
                    setTimeout(() => document.querySelector("p[name=error]").style.opacity = "1", 100)
                    alert("大変申し訳ございません、エラーにより送信できませんでした。お時間をおいて、もう一度お試しください。")
                } else {
                    setIsSent(true);
                    setTimeout(() => document.querySelector("p[name=done]").style.opacity = "1", 100)
                    res.text()
                    f.reset()
                    setIsDisabled(true)
                }
                setTimeout(() => document.querySelector("p[name=done],p[name=error]").style.opacity = "0", 1500)
            })
            .then((text) => {
            })
            .catch(e => {
                console.log(e)
            })
    }

    return (
        <div className='news pt-6 container' id="contact">
            <h1>Contact</h1>
            <form>
                <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input type="text" className="form-control" name="name" />
                </div>
                <div className="mb-3">
                    <label className="form-label">Email</label>
                    <input type="email" className="form-control" name="email" aria-describedby="emailHelp" />
                    <div id="emailHelp" className="text-secondary">We'll never share your email with anyone else.</div>
                </div>
                <div>
                    <label className="form-label">Message</label>
                    <textarea cols="30" rows="10" name="message" className='form-control'></textarea>
                </div>
                <div className="mb-3">
                    <label className="form-check-label">
                        <input type="checkbox" className="" name="chk" ref={ref} onClick={onChangeChk} />
                        Check me out
                    </label>
                </div>
                <button name="button" type="button" onClick={post}
                    disabled={isDisabled}
                    className="btn anim-1 btn-primary p-2" style={{ "width": 150 }}>送信</button>
                {isSent && <p name="done" className='fs-2 anim-3' style={{ color: "#6aea15", opacity: 0 }}>✔</p>}
                {isError && <p name="error" className='fs-2 anim-3' style={{ color: "#ff3333", opacity: 0 }}>❌</p>}
            </form>
        </div>
    )
}
export default Home